.app {
  display: flex;
  position: absolute;
  inset: 0;
  justify-content: flex-start;
}

.title {
  margin: 2vmin;
  font-size: 10vmin;
  color: white;
}
